@tailwind base;
/* @tailwind components; */

.btn {
  @apply px-5 py-3 shadow-sm transition ease-in-out duration-300 rounded leading-snug whitespace-nowrap text-base font-semibold;
}

.btn.btn-sm {
  @apply px-4 py-2 text-sm;
}

.btn.btn-lg {
  @apply text-lg px-8 py-4;
}

.btn-primary {
  @apply text-white bg-blue-500 hover:bg-blue-600;
}

.btn-primary.btn-outline {
  @apply text-blue-600 border border-blue-600 bg-transparent hover:bg-blue-600 hover:text-white;
}

.btn-secondary {
  @apply text-white bg-indigo-500 hover:bg-indigo-600;
}

.btn-secondary.btn-outline {
  @apply text-indigo-600 border border-indigo-600 bg-transparent hover:bg-indigo-600 hover:text-white;
}

.btn-tertiary {
  @apply text-white bg-gray-600 hover:bg-gray-700;
}

.btn-tertiary.btn-outline {
  @apply text-gray-600 border border-gray-600 bg-transparent hover:bg-gray-600 hover:text-white;
}

@tailwind utilities;

/* remove blue from links */

nav a,
nav a:focus,
nav a:active,
nav a:hover {
  color: #fff;
}

/* bedrock hides graph connections */

div svg {
  max-height: none;
}
